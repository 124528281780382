<template>
	<div class="agreement">
		<base-navigation-bar
			:isLeft="true"
			title="自动续费协议"
			color="#000000"
			@handleBack="handleBack"
			returnArrow="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/returnArrow.png"
		/>

		<div class="content">
		本规则被视为《随心会员服务协议》的补充协议，是其不可分割的组成部分，与其构成统一整体。如本规则与《会员服务协议》存在冲突，则以本规则为准。</br>
您点击订阅、开通及使用随心会员自动续费服务(以下称“本自动续费服务”),则视为您同意本规则。</br>
随心系列会员包括：腾讯视频VIP会员、爱奇艺视频VIP会员、芒果TV会员、哔哩哔哩会员、喜马拉雅会员、优酷视频会员、蜻蜓FM会员、懒人畅听会员、QQ绿钻会员、网易云黑胶会员、酷我音乐豪华会员，以上每月N选一(合称为：“随心会员”)</br>
您理解并同意：</br>
1.如您通过支付宝开通本自动续费服务，且在订阅期内未主动明确取消本自动续费服务，则支付宝公司将在每个订阅期即将到期的前24小时从您开通本自动续费服务时绑定的支付宝账户(以下统称“账户”)余额中自动代扣下一个订阅期的服务费，从而延长该订阅期对应的会员有效期(具体以支付宝实际扣费规则为准)。您应关注上述账户及可扣款的余额状况，如因账户问题或余额不足导致续费失败等风险及/或损失将由您自行承担。为便于您了解自动续费情况，支付宝及秋末公司将以【站内信、微信推送、短信或邮件】等方式进行扣款前通知，请您注意接收。需要提醒您特别注意，基于自动续费服务的特点，如果您在订阅期内未明确取消自动续费服务，支付宝将根据您的委托向相关主体发出下一个订阅期的代扣指令，原则上对基于该指令扣除的费用将不予退还。</br>
2.订阅期：1个月</br>
3.您确认并同意，秋末拥有调整服务价格或停止提供自动续费服务的权利。如秋末拟对您开通自动续费服务时的服务价格做出调整，秋末将以适当方式(包括但不限于站内公告、短信等)事先通知您。如您不同意服务价格调整，您可以根据本规则取消本自动续费服务；如您未取消本自动续费服务，则支付宝公司后续代扣的费用将以调整后的服务费用为准；如您在订阅期到期前24小时内取消，则支付宝公司将可能会按照调整后的服务费用扣除下一个订阅期的服务费用后停止代扣服务。在本自动续费服务取消后，如您再次开通自动续费服务，支付宝公司自动代扣的服务费用以届时页面显示的服务费用为准。</br>
4.自动续费服务退订方法如下：</br>
打开“支付宝App”-->点击“我的”-->点击“设置”-->点击“支付设置”-->点击“免密支付/自动扣款”-->在自动续费页面找到您所开通的“浙江秋末信息科技有限公司”自动续费服务，点击关闭即可；</br>
5.秋末亦可以根据运营策略需要而停止继续向您提供自动续费服务，您的随心会员服务期限自当前服务周期届满之日起终止。</br>
6.随心会员服务协议：<a href="https://asa.qiumo.net.cn/aspage/agreement/atAppfwxy">https://asa.qiumo.net.cn/aspage/agreement/atAppfwxy</a></br>
7.隐私政策：<a href="https://asa.qiumo.net.cn/aspage/agreement/atAppyszc">https://asa.qiumo.net.cn/aspage/agreement/atAppyszc</a></br>
浙江秋末信息科技有限公司</br>

		</div>
	</div>
</template>

<script>
import BaseNavigationBar from "@/components/BaseNavigationBar/BaseNavigationBar.vue";
export default {
	components: {
		BaseNavigationBar,
	},
	data() {
		return {
		};
	},
	methods: {
		handleBack() {
		  this.$router.go(-1);
		}
	}
}
</script>

<style lang="scss">
.agreement {
	background-color: #ffffff;

	.content {
		color: #333333;
		line-height: 50px;
		background-color: #fff;
		padding: 25px 36px 100px 24px;
		font-size: 28px;
		position: relative;
		word-wrap: break-word;

		.goBack {
			position: fixed;
			left: 0;

			.icon {
				width: 80px;
			}
		}
	}
}
</style>
